<template>
  <div class="esims">
    <div class="mine-title">{{ $t("route.flow") }}</div>
    <a-spin size="large" :spinning="isLoading" class="content">
      <div class="esim-change">
        <router-link to="/esimchange" class="router-link">
          <a-icon type="question-circle" theme="filled" class="icon" />
          <span>{{ $t("esimchange.title") }}</span>
        </router-link>
      </div>
      <ul class="records">
        <li v-for="(item, index) in activeList" :key="index">
          <div class="iccid active">
            <span>ICCID：</span>
            <span
              class="copy"
              @click="handleCopy"
              :data-clipboard-text="item.iccid"
              >{{ item.iccid }}</span
            >
          </div>
          <div class="item-info">
            <div class="item-name flex-center-between">
              <a-space>
                <div class="item-icon">
                  <img :src="item.cover" alt="" />
                </div>
                <span>{{ item.product_name }} {{ item.spec }}</span>
              </a-space>
              <div class="item-status item-status-active">
                {{ item.status_name }}
              </div>
            </div>
            <div class="grid">
              <a-row type="flex" :gutter="[20, 10]" class="first-grid">
                <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <div class="esim-info-warrper">
                    <div class="title">
                      {{ $t("mine.flow_5") }}{{ item.progress }}%
                    </div>
                    <div class="esim-info-inner flex-center-column">
                      <a-progress
                        :width="160"
                        :strokeWidth="10"
                        type="dashboard"
                        stroke-linecap="round"
                        :percent="100 - item.progress"
                        strokeColor="#642ec7"
                      >
                        <template #format="percent">
                          <div class="progress-text">{{ percent }}%</div>
                          <div class="progress-tips">{{ $t("mine.data") }}</div>
                        </template>
                      </a-progress>
                      <div
                        class="item-active-tips"
                        v-html="$t('mine.flow_17')"
                      ></div>
                    </div>
                  </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                  <div class="title first-grid-title">{{ item.tips }}</div>
                  <div class="esim-time">
                    <span>{{ $t("mine.flow_6") }}</span>
                    <span>{{ item.active_time }}</span>
                  </div>
                  <div class="esim-time">
                    <span>{{ $t("mine.flow_7") }}</span>
                    <span>{{ item.expire_time }}</span>
                  </div>
                </a-col>
              </a-row>
              <a-row type="flex" :gutter="[10, 10]">
                <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <div class="esim-info-warrper">
                    <div class="title">{{ $t("mine.flow_8") }}</div>
                    <div class="esim-info-inner flex">
                      <vue-qr
                        :text="item.esim_code"
                        :size="160"
                        :margin="0"
                      ></vue-qr>
                    </div>
                  </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                  <div class="title">{{ $t("mine.flow_9") }}</div>
                  <a-row
                    type="flex"
                    align="middle"
                    :gutter="[
                      { xs: 10, md: 10 },
                      { xs: 5, md: 14 },
                    ]"
                  >
                    <a-col :span="24">
                      <div class="subtitle flex-center-start">
                        <a-icon
                          type="apple"
                          theme="filled"
                          class="subtitle-icon"
                        />
                        <span>IOS</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <span class="item-label">{{ $t("mine.flow_15") }}</span>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.smdp_address"
                        @click="handleCopy"
                      >
                        <span>{{ item.smdp_address }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <span class="item-label">{{ $t("mine.flow_10") }}</span>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.activation_code"
                        @click="handleCopy"
                      >
                        <span>{{ item.activation_code }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="subtitle flex-center-start">
                        <a-icon
                          type="android"
                          theme="filled"
                          class="subtitle-icon"
                        />
                        <span>Android</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <div class="item-label">
                        <span>{{ $t("mine.flow_15") }}</span>
                        <span>{{ $t("mine.flow_10") }}</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.esim_code"
                        @click="handleCopy"
                      >
                        <span>{{ item.esim_code }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="item-footer">
              <div v-if="$i18n.locale == 'zh-tw'">
                {{ $t("mine.flow_11") }}：
                <span @click="handeStepImagePreview('zh-hk')">香港版</span>
                <span @click="handeStepImagePreview('zh-tw')">台灣版</span>
              </div>
              <div v-else>
                <span @click="handeStepImagePreview($i18n.locale)">{{
                  $t("mine.flow_11")
                }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="records">
        <li v-for="(item, index) in unactiveList" :key="index">
          <div class="iccid pending">
            <span>ICCID：</span>
            <span
              class="copy"
              @click="handleCopy"
              :data-clipboard-text="item.iccid"
              >{{ item.iccid }}</span
            >
          </div>
          <div class="item-info">
            <div class="item-name flex-center-between">
              <a-space>
                <div class="item-icon">
                  <img :src="item.cover" alt="" />
                </div>
                <span>{{ item.product_name }} {{ item.spec }}</span>
              </a-space>
              <div class="item-status item-status-pending">
                {{ item.status_name }}
              </div>
            </div>
            <div class="grid">
              <a-row type="flex" :gutter="[10, 10]">
                <a-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
                  <div class="esim-info-warrper">
                    <div class="title">{{ $t("mine.flow_8") }}</div>

                    <div class="esim-info-inner flex-center-column">
                      <vue-qr
                        :text="item.esim_code"
                        :size="160"
                        :margin="0"
                      ></vue-qr>
                      <div class="expired-time">{{ item.expire_tips }}</div>
                    </div>
                  </div>
                </a-col>
                <a-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
                  <div class="title">{{ $t("mine.flow_9") }}</div>
                  <a-row
                    type="flex"
                    align="middle"
                    :gutter="[
                      { xs: 10, md: 10 },
                      { xs: 5, md: 14 },
                    ]"
                  >
                    <a-col :span="24">
                      <div class="subtitle flex-center-start">
                        <a-icon
                          type="apple"
                          theme="filled"
                          class="subtitle-icon"
                        />
                        <span>IOS</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <span class="item-label">{{ $t("mine.flow_15") }}</span>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.smdp_address"
                        @click="handleCopy"
                      >
                        <span>{{ item.smdp_address }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <span class="item-label">{{ $t("mine.flow_10") }}</span>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.activation_code"
                        @click="handleCopy"
                      >
                        <span>{{ item.activation_code }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="subtitle flex-center-start">
                        <a-icon
                          type="android"
                          theme="filled"
                          class="subtitle-icon"
                        />
                        <span>Android</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
                      <div class="item-label">
                        <span>{{ $t("mine.flow_15") }}</span>
                        <span>{{ $t("mine.flow_10") }}</span>
                      </div>
                    </a-col>
                    <a-col :xs="24" :sm="24" :md="16" :lg="16" :xl="14">
                      <div
                        class="copy flex-center-between"
                        :data-clipboard-text="item.esim_code"
                        @click="handleCopy"
                      >
                        <span>{{ item.esim_code }}</span>
                        <span class="iconfont icon-copy"></span>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
            <div class="item-footer">
              <div v-if="$i18n.locale == 'zh-tw'">
                {{ $t("mine.flow_11") }}：
                <span @click="handeStepImagePreview('zh-hk')">香港版</span>
                <span @click="handeStepImagePreview('zh-tw')">台灣版</span>
              </div>
              <div v-else>
                <span @click="handeStepImagePreview($i18n.locale)">{{
                  $t("mine.flow_11")
                }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul class="records">
        <li v-for="(item, index) in expiredList" :key="index">
          <div class="iccid expired">
            <span>ICCID：</span>
            <span
              class="copy"
              @click="handleCopy"
              :data-clipboard-text="item.iccid"
              >{{ item.iccid }}</span
            >
          </div>
          <div class="item-info">
            <div class="item-name flex-center-between">
              <a-space>
                <div class="item-icon">
                  <img :src="item.cover" alt="" />
                </div>
                <span>{{ item.product_name }} {{ item.spec }}</span>
              </a-space>
              <div class="item-status item-status-expired">
                {{ item.status_name }}
              </div>
            </div>
            <div class="expired-time">{{ item.expire_tips }}</div>
          </div>
        </li>
      </ul>
      <div v-show="isEmpty" class="empty-wrapper flex">
        <a-empty :image="require('@/assets/mydata_none.png')">
          <span slot="description" class="empty-text">
            {{ $t("mine.flow_14") }}
          </span>
        </a-empty>
      </div>
    </a-spin>
  </div>
</template>
<script>
import VueQr from "vue-qr";
import Clipboard from "clipboard";
import { message, Progress } from "ant-design-vue";
import { ActivateStepImagePreview } from "../utils/imagePreview";
import { UserProductAPI, UserExpiredProductAPI } from "../api/api";
export default {
  components: {
    VueQr,
    [Progress.name]: Progress,
  },
  data() {
    return {
      isLoading: false,
      unactiveList: [],
      activeList: [],
      expiredList: [],
      gradientColor: {
        "0%": "#773DE1",
        "100%": "#E538BC",
      },
    };
  },
  computed: {
    isEmpty() {
      return (
        !this.unactiveList.length &&
        !this.activeList.length &&
        !this.expiredList.length
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        const {
          data: { unactive_list, active_list },
        } = await UserProductAPI();
        this.unactiveList = unactive_list;
        this.activeList = active_list;
        const { data } = await UserExpiredProductAPI();
        this.expiredList = data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    handeStepImagePreview(locale) {
      if (document.body.clientWidth <= 768) {
        this.$router.push({ name: "Instruction", query: { locale } });
        return;
      }
      ActivateStepImagePreview(locale);
    },
    handleCopy() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        message.success(this.$t("mine.flow_3"));
        clipboard.destroy();
      });
      clipboard.on("error", (e) => {
        message.warning(this.$t("mine.flow_4"));
        clipboard.destroy();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.esims {
  min-height: 70vh;
  .esim-change {
    user-select: none;
    .router-link {
      display: inline-flex;
      align-items: center;
    }
    .icon {
      cursor: pointer;
      color: $color;
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .esim-info-warrper {
    height: 100%;
    display: flex;
    flex-direction: column;
    .item-active-tips {
      color: #5d5d5d;
      font-size: 12px;
    }
    .esim-info-inner {
      flex: 1;
      margin-right: 40px;
      margin-bottom: 20px;
      @include respond-to("pad") {
        margin-right: 0;
      }
      @include respond-to("padair") {
        margin-right: 0;
      }
    }
  }
  .content {
    margin-top: 20px;
    .records {
      margin-top: 20px;
      li {
        margin-top: 20px;
        padding-bottom: 10px;
        box-shadow: 0px 2px 10px 0px rgba(23, 59, 92, 0.08);
        .item-info {
          padding: 0 20px;
          .item-name {
            padding: 10px 0;
            color: #000000;
            font-weight: 600;
            border-bottom: 1px solid #ebebeb;
            .item-icon {
              width: 40px;
              img {
                object-fit: cover;
                border-radius: 4px;
              }
            }
            .item-status {
              white-space: nowrap;
              margin-left: 10px;
            }
            .item-status-active {
              color: #b50000;
            }
            .item-status-pending {
              color: $color;
            }
            .item-status-expired {
              color: #999999;
            }
          }
          .expired-time {
            margin-top: 10px;
            color: #222222;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .grid {
          margin: 20px 0;
          .progress-text {
            font-size: 16px;
            font-weight: 600;
          }
          .progress-tips {
            margin-top: 10px;
            font-size: 12px;
          }
          .title {
            color: #222222;
            font-weight: 600;
            margin-bottom: 20px;
          }
          .subtitle {
            color: #222222;
            font-weight: 600;
            .subtitle-icon {
              margin-right: 10px;
            }
          }
          .esim-time {
            color: #5d5d5d;
            line-height: 2;
            span:first-child {
              margin-right: 10px;
            }
          }
          .item-label {
            display: flex;
            flex-wrap: wrap;
            span {
              &:first-child {
                margin-right: 10px;
              }
            }
          }
          .copy {
            cursor: pointer;
            padding: 10px;
            word-break: break-all;
            border-radius: 4px;
            background-color: #f2f3f6;
            .icon-copy {
              margin-left: 10px;
              color: $color;
            }
          }
        }
        @include respond-to("pad") {
          .first-grid {
            flex-flow: column-reverse;
          }
        }
        .item-footer {
          user-select: none;
          border-top: 1px solid #ebebeb;
          padding-top: 10px;
          font-weight: 600;
          color: $color;
          span {
            cursor: pointer;
            margin-right: 10px;
            text-decoration: underline;
          }
          @media (max-width: 320px) {
            font-size: 14px;
          }
        }
        .iccid {
          color: #fff;
          padding: 14px 20px;
          span {
            user-select: none;
          }
          .copy {
            user-select: all;
            cursor: pointer;
          }
        }
        .active {
          background-color: #b50000;
        }
        .pending {
          background-color: $color;
        }
        .expired {
          background-color: #e4e7ed;
        }
      }
    }
  }
  @include respond-to("phone") {
    .esim-info-warrper {
      .esim-info-inner {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    .content {
      .records {
        li {
          .item-info {
            padding: 0 16px;
            .item-name {
              padding: 10px 0;
              font-size: 14px;
              .item-icon {
                width: 30px;
              }
            }
          }
          .grid {
            margin: 10px 0;
            .title {
              margin-bottom: 20px;
              @media (max-width: 320px) {
                font-size: 14px;
                margin-bottom: 10px;
              }
            }
            .subtitle {
              @media (max-width: 320px) {
                font-size: 14px;
              }
            }
            .esim-time {
              font-size: 14px;
            }
            .item-label {
              font-size: 14px;
            }
            .copy {
              font-size: 14px;
              padding: 8px;
            }
          }
          .first-grid {
            flex-flow: column-reverse;
            .first-grid-title {
              margin-bottom: 10px;
            }
          }
          .iccid {
            font-size: 14px;
            padding: 8px 20px;
          }
          .expired {
          }
        }
      }
    }
  }
}
</style>
